<template>
  <b-card>
    <title-name />
    <option-groups />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import TitleName from '@/views/Admin/Defines/Estate_config_options/elements/Title.vue'
import OptionGroups from '@/views/Admin/Defines/Estate_config_options/elements/OptionGroups.vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    TitleName,
    OptionGroups,
  },
}
</script>
